import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "./app.slice";

const POSTS_SLICE_NAME = "post";

export type State = {};

const initialState: State = {};

export const postSlice = createSlice({
  name: POSTS_SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logoutAction, (state) => {
      state = initialState;
      return state;
    });
  },
});
