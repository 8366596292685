import { Box, Column, HStack, Image, Row, Text, VStack } from "native-base";
import { ProfileAvatar } from "../../../core/components/profile-avatar";
import { OptimizedCdnImage } from "../../../core/components/optimzed-cdn-image";
import { ProfileActions } from "../../../profile/components/profile-actions";
import { TouchableOpacity } from "react-native";
import React from "react";
import { PostReturnDto } from "../../../../services/api-service-sub-services/studies-api-service";
import { useProfileValues } from "../../../profile/hooks/use-profile-values";
import { formatDateTime } from "../../../core/utils/date.util";

export type PostHeaderProps = {
  onPress: () => void | Promise<void>;
  post: PostReturnDto;
};

export const PostHeader = (props: PostHeaderProps) => {
  const profileValues = useProfileValues(props.post.profile.properties);

  return (
    <TouchableOpacity onPress={() => props.onPress()}>
      <HStack>
        <ProfileAvatar profile={props.post.profile} length={12} />
        <Column ml="3">
          <Text variant="heading6" bold>
            {profileValues.displayName}
          </Text>
          <Text
            variant="subtitle2"
            lineHeight={16}
            fontWeight={400}
            color="darkGrey"
          >
            {formatDateTime(props.post.date!)}
          </Text>

          {props.post.labels && props.post.labels.length > 0 && (
            <VStack space="1" mt="1">
              {props.post.labels.map((label) => {
                return (
                  <Row
                    key={label._id}
                    alignItems="center"
                    justifyContent="flex-start"
                    space="1"
                    flexBasis="0"
                  >
                    {label.imageUrl && (
                      <OptimizedCdnImage
                        uri={label.imageUrl}
                        width={50}
                        height={50}
                        fit="cover"
                        render={(source) => (
                          <Image
                            src={source.uri}
                            alt="image"
                            height="4"
                            minWidth="4"
                            maxWidth="20"
                            resizeMode="contain"
                          />
                        )}
                      />
                    )}
                    <Text
                      variant="small"
                      fontSize="12"
                      color={label.color}
                      bold
                      letterSpacing="xl"
                    >
                      {!label.isTitleHidden ? label.title : ""}
                    </Text>
                  </Row>
                );
              })}
            </VStack>
          )}
        </Column>
        <Box position="absolute" top="2" right="0">
          <ProfileActions profileId={props.post.profile._id} />
        </Box>
      </HStack>
    </TouchableOpacity>
  );
};
