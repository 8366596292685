import React, { useCallback, useEffect, useState } from "react";
import { InAppBrowserScreenProps } from "./in-app-browser.screen";
import { StackScreenProps } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { Routes } from "../../navigation/routes";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";

type InAppBrowserContainerProps = StackScreenProps<
  AppStackParamList,
  Routes.APP_STACK__IN_APP_BROWSER
>;
export const InAppBrowserContainer = (
  Screen: React.FC<InAppBrowserScreenProps>
) => {
  return function _(props: InAppBrowserContainerProps) {
    const studyId = useAppSelector(selectUserStudyId);
    const profile = useAppSelector(selectUserProfile);
    const dispatch = useAppDispatch();
    const [currentURL, setCurrentURL] = useState(props.route.params.url);
    const track = useTrackEvent();

    useEffect(() => {
      track(events.userNavigatedExternalLink, {
        url: currentURL,
      });
    }, [currentURL]);

    const onInAppBrowserURLChange = useCallback(
      (url: string) => {
        if (!studyId || !profile) {
          return;
        }

        track(events.userChangedExternalLink, {
          url: url,
        });

        if (url === "https://spark-social.com/app/redirect") {
          // close the in-app browser
          props.navigation.pop();
        } else {
          setCurrentURL(url);
        }
      },
      [dispatch, studyId, profile, props.navigation]
    );

    useEffect(() => {
      if (!studyId || !profile) {
        return;
      }
      track(events.userOpenedUrlFromApp, {
        url: currentURL,
      });
      return () => {
        track(events.userClosedInAppBrowser, {
          url: currentURL,
        });
      };
    }, [studyId, profile]);

    return (
      <Screen
        url={props.route.params.url}
        title={props.route.params.title}
        userPressedCloseButton={() => props.navigation.pop()}
        onInAppBrowserURLChange={onInAppBrowserURLChange}
      />
    );
  };
};
