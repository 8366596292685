import { Box, Image, Row } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import React, { useMemo } from "react";
import { useTreatmentArmExperience } from "../../../core/hooks/use-treatment-arm-experience";
import { Interaction } from "../../post.container";
import { SmallActionButton } from "../../../core/components/small-action-button.component";

export type PostReactionsProps = {
  activeInteraction: string;
  onInteractionButtonPress: (interaction: string) => void;
  isInteractionsLoading: boolean;

  totalComments?: number;
  onCommentButtonPress?: () => void;

  interaction: Interaction;
};

export const PostReactions = (props: PostReactionsProps) => {
  const treatmentArmExperience = useTreatmentArmExperience();

  const interactionVariantColorOverride = useMemo(() => {
    if (treatmentArmExperience.interactions.isWithVariants) {
      const { variants, variant } = treatmentArmExperience.interactions;
      const matchedVariant = variants.find(
        (interaction) => interaction.name === variant
      );
      if (matchedVariant) {
        return matchedVariant.value;
      }
      return undefined;
    }
  }, [treatmentArmExperience]);

  return (
    <Row alignItems="flex-start" space={1}>
      {/** custom interactions */}
      {treatmentArmExperience.interactions.customInteractions.map(
        (interaction) => {
          return (
            <SmallActionButton
              key={interaction._id}
              isDisabled={props.isInteractionsLoading}
              isActive={props.activeInteraction === interaction.name}
              onPress={() => props.onInteractionButtonPress(interaction.name)}
              counterValue={props.interaction[interaction.name] || 0}
              icon={
                <Box height="20px" width="20px">
                  <Image
                    src={interaction.icon}
                    height="100%"
                    width="100%"
                    alt="interaction"
                  />
                </Box>
              }
            />
          );
        }
      )}

      {/** like */}
      {treatmentArmExperience.interactions.isLikesEnabled && (
        <SmallActionButton
          isDisabled={props.isInteractionsLoading}
          isActive={props.activeInteraction === "like"}
          onPress={() => props.onInteractionButtonPress("like")}
          counterValue={props.interaction.like}
          icon={
            <AntDesign
              name="like2"
              size={20}
              color={interactionVariantColorOverride}
            />
          }
        />
      )}

      {/** dislike */}
      {treatmentArmExperience.interactions.isDislikesEnabled && (
        <SmallActionButton
          isDisabled={props.isInteractionsLoading}
          isActive={props.activeInteraction === "dislike"}
          onPress={() => props.onInteractionButtonPress("dislike")}
          counterValue={props.interaction.dislike}
          icon={
            <AntDesign
              name="dislike2"
              size={20}
              color={interactionVariantColorOverride}
            />
          }
        />
      )}

      {/** comments */}
      {treatmentArmExperience.interactions.isCommentsEnabled &&
        !!props.onCommentButtonPress && (
          <SmallActionButton
            onPress={() => {
              props.onCommentButtonPress?.();
            }}
            counterValue={props.totalComments || 0}
            icon={<AntDesign name="message1" size={20} />}
          />
        )}
    </Row>
  );
};
