export const events = {
  // authentication
  userSignedUp: "User has signed up",
  userSignedIn: "User has signed in",
  userEnteredInvalidInviteCode: "User has entered an invalid invite code",

  // general
  unableToUpdatesFromEAS: "Unable to perform EAS update",
  userShownAnErrorMessage: "User is shown an error message",

  // in-app browser
  userNavigatedExternalLink: "User has navigated to an external URL",
  userChangedExternalLink: "User has navigated to a different URL",
  userOpenedUrlFromApp: "User has opened url from app",
  userClosedInAppBrowser: "User closed in app browser",

  // surveys
  userStartedSurvey: "User started survey",
  userCompletedSurvey: "User completed survey",
  userRedirectedFromAnExistingCompletedSurvey:
    "User redirected from an existing completed survey",

  // comments
  userCreatedComment: "User has created a comment",
  userOpenedCommentsThead: "User has opened a comment thread",
  userUnableToCreateComment: "User has been unable to create a comment",
  userAddedInteractionToComment: "User added interaction to a comment",
  userCancelledCreatingComment: "User has cancelled creating a comment",

  commentEnteredViewport: "Comment entered into the viewport",
  commentExitedViewport: "Comment exited from the viewport",
  commentDwellTimeMeasured: "Comment dwell time measured",
  commentPositionMeasured: "Comment position measured",

  // post
  userStoppedWritingPost: "User has stopped writing a post",
  userAddedInteractionToPost: "User has added an interaction to a post",
  useOpenedNewPostScreenFromProfile:
    "User has opened new post screen from a profile",
  userOpenedSinglePost: "User has opened a single post",
  userCancelledCreatingPost: "User has cancelled creating a post",
  postEnteredViewport: "Post entered into the viewport",
  postExitedViewport: "Post exited from the viewport",
  postDwellTimeMeasured: "Post dwell time measured",
  postPositionMeasured: "Post position measured",
  userUnableToCreatePost: "User is unable to create a post",
  userAddedImageToPost: "User has added an image to a post",
  userCreatedPost: "User has created a post",

  // feed
  userViewedFeed: "User has entered the feed screen",
  userRefreshedFeed: "User refreshed feed",
  userReadTreatmentArmInstructions: "User read treatment arm instructions",

  // profile
  userViewedProfile: "User has viewed a profile",
  userViewedOwnProfile: "User has entered their own profile screen",

  userFollowedProfile: "User has followed a profile",
  userUnfollowedProfile: "User has unfollowed a profile",

  userCompletedProfileSetup: "User has completed profile setup",

  // notifications
  userViewedNotifications: "User has entered the notifications screen",
  userReadNotification: "User has read a notification",
  userUnreadNotification: "User has unread a notification",
  userOpenedNotification: "User has opened a notification",
  userLoadedMoreNotifications: "User has loaded more notifications",

  // settings
  userOpenedSettingsScreen: "User has entered the settings screen",

  // pop-ups
  popupOpened: "Popup has been opened",
  popupClosed: "Popup has been closed",
  popupCheckboxValueChange: "User has changed the checkbox value in the popup",
  popupConsent: "User has pressed the continue button in the popup",
  popupAutomaticallyClosed:
    "Popup has been automatically closed after expiration",
};

export enum Modules {
  AUTHENTICATION = "authentication",
  GENERAL = "general",
  IN_APP_BROWSER = "in-app browser",
  SURVEYS = "surveys",
  COMMENTS = "comments",
  POSTS = "posts",
  FEED = "feed",
  PROFILE = "profile",
  NOTIFICATIONS = "notifications",
  SETTINGS = "settings",
  POPUPS = "popups",
}
