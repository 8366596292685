import React, { useCallback, useRef, useState } from "react";
import {
  AspectRatio,
  Box,
  Column,
  HStack,
  Image,
  KeyboardAvoidingView,
  ScrollView,
  Text,
  TextArea,
  useTheme,
  View,
  VStack,
} from "native-base";
import { ParticipantProfileWithPropertiesDto } from "../../services/api-service-sub-services/users-api-service";
import { ProfileAvatar } from "../core/components/profile-avatar";
import dayjs from "dayjs";
import { Keyboard, Platform, TouchableOpacity } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import { extractURLs } from "../core/utils/url.util";
import { LinkPreviewComponent } from "./components/common/link-preview.component";
import { useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import { useProfileValues } from "../profile/hooks/use-profile-values";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { useMentions } from "react-native-controlled-mentions";
import { MentionSuggestionsList } from "./components/common/mentions-suggestion-list";
import { useFeatureAvailability } from "../core/hooks/use-feature-availability";
import { selectPostPromptPlaceholderText } from "../../store/app.slice";
import { CircularButton } from "../core/components/circular-button.component";
import { HorizontalActionButton } from "../core/components/horizontal-action-button.component";
import { logger } from "../core/utils/logger.util";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";
import { formatDateTime } from "../core/utils/date.util";

export type NewPostComponentProps = {
  profile?: ParticipantProfileWithPropertiesDto;
  onPostButtonPress: (content: string) => Promise<void>;
  onSelectPhotoButtonPress: () => Promise<void>;
  image: string;
  onRemovePhotoButtonPress: () => void;
  onCancelButtonPress: () => void;
};

export const NewPostScreen = (props: NewPostComponentProps) => {
  const profileValues = useProfileValues(props.profile?.properties);
  const debounceTimeout = 1200; // Set the duration (in milliseconds) to wait before triggering the action

  const isBotTaggingEnabled = useFeatureAvailability("bot-tagging");

  const studyId = useAppSelector(selectUserStudyId);
  const profile = useAppSelector(selectUserProfile);
  const promptPlaceholderText = useAppSelector(selectPostPromptPlaceholderText);

  const debounceTimer = useRef<number>();
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const postStartTimestampRef = useRef<string | null>(null);

  const [textAreaHeight, setTextAreaHeight] = useState<number>(30);
  const [postContent, setPostContent] = useState<string>("");
  const [url, setUrl] = useState<string | undefined>();
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const theme = useTheme();
  const track = useTrackEvent();

  const handleChangeText = (text: string) => {
    setPostContent(text);

    // Clear any previous timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer to trigger the action after the specified duration
    debounceTimer.current = setTimeout(() => {
      const urls = extractURLs(text);
      if (urls.length > 0) {
        setUrl(urls[0]);
      } else {
        setUrl(undefined);
      }
    }, debounceTimeout) as any; // Use
  };

  const { textInputProps, triggers } = useMentions({
    value: postContent,
    onChange: handleChangeText,
    triggersConfig: {
      mention: {
        trigger: "@",
        textStyle: { fontWeight: "bold" },
      },
    },
  });

  const onClose = () => {
    Keyboard.dismiss();
    setPostContent("");
    textAreaRef.current?.blur();
    track(events.userCancelledCreatingPost, {
      content: postContent,
    });
    props.onCancelButtonPress();
  };

  const trackTimeWritingPost = useCallback(() => {
    if (postStartTimestampRef.current && profile?._id && studyId) {
      track(events.userStoppedWritingPost, {
        timeWriting: Math.round(
          dayjs.utc().diff(postStartTimestampRef.current) / 1000
        ),
        content: postContent,
      });
    }

    postStartTimestampRef.current = null;
  }, [profile?._id, studyId, postContent]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <VStack variant="scrollable-screen" safeAreaTop backgroundColor="white">
        <HStack
          px="5"
          pt="3"
          pb="3"
          justifyContent="space-between"
          bg="white"
          alignItems="center"
        >
          <CircularButton
            onPress={() => {
              trackTimeWritingPost();
              onClose();
            }}
            icon={<MaterialIcons name="close" size={22} />}
          />

          <Text bold alignSelf="center" variant="subtitle1">
            Create Post
          </Text>
          <CircularButton
            onPress={async () => {
              try {
                setIsBusy(true);
                trackTimeWritingPost();
                await props.onPostButtonPress(postContent);
                onClose();
              } catch (e: any) {
                logger.error(e);
              } finally {
                setIsBusy(false);
              }
            }}
            disabled={postContent.length === 0 || isBusy}
            isLoading={isBusy}
            icon={<MaterialIcons name="send" size={22} />}
          />
        </HStack>

        <ScrollView
          keyboardDismissMode="interactive"
          backgroundColor="xLightGrey"
          borderTopRadius="3xl"
          overflow="hidden"
          flex={1}
        >
          <VStack space="5" px="5" pt="3">
            <HStack>
              <ProfileAvatar profile={props.profile} />
              <Column ml="3">
                <Text variant="heading">{profileValues.displayName}</Text>
                <Text variant="small" color="darkGrey">
                  {formatDateTime(dayjs().toDate())}
                </Text>
              </Column>
            </HStack>
            <VStack space="4">
              <TextArea
                autoFocus
                px="0"
                ref={textAreaRef}
                placeholder={promptPlaceholderText}
                placeholderTextColor="darkGrey"
                h={textAreaHeight + 30}
                {...(Platform.OS !== "web" && {
                  onContentSizeChange: (event) => {
                    setTextAreaHeight(event.nativeEvent.contentSize.height);
                  },
                })}
                autoCompleteType={undefined}
                fontSize={RFValue(16, 750)}
                borderWidth="0"
                onFocus={() => {
                  if (postStartTimestampRef.current === null) {
                    postStartTimestampRef.current = dayjs.utc().toISOString();
                  }
                }}
                {...(isBotTaggingEnabled
                  ? textInputProps
                  : {
                      value: postContent,
                      onChangeText: handleChangeText,
                    })}
              />

              {/* conditionally renders the suggestion list of mentions based on the provided triggers */}
              <MentionSuggestionsList {...triggers.mention} />

              {url && (
                <View borderRadius="8px" overflow="hidden">
                  <LinkPreviewComponent url={url} />
                </View>
              )}

              {props.image.length > 0 && (
                <View>
                  <Box position="absolute" top={-7} left={-7} zIndex="10">
                    <TouchableOpacity onPress={props.onRemovePhotoButtonPress}>
                      <AntDesign
                        name="closecircle"
                        size={20}
                        color={theme.colors.primary as unknown as string}
                      />
                    </TouchableOpacity>
                  </Box>
                  <AspectRatio
                    alignSelf="flex-start"
                    borderRadius="8"
                    overflow="hidden"
                    width="40%"
                    ratio={1}
                  >
                    <Image
                      src={props.image}
                      alt="post-image"
                      resizeMode="cover"
                    />
                  </AspectRatio>
                </View>
              )}
            </VStack>
          </VStack>
        </ScrollView>
        <Box px={3} pb={3} backgroundColor="xLightGrey">
          {Platform.OS !== "web" && (
            <HorizontalActionButton
              onSelect={props.onSelectPhotoButtonPress}
              label="Upload photo"
              icon={
                <MaterialIcons
                  name="insert-photo"
                  size={24}
                  color={theme.colors.secondary as unknown as string}
                />
              }
            />
          )}
        </Box>
      </VStack>
    </KeyboardAvoidingView>
  );
};
