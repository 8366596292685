import { createContext, PropsWithChildren, useContext } from "react";
import { useTimer } from "react-use-precision-timer";
import lodash from "lodash";
import {
  useStudiesControllerCreateBotCommentMutation,
  useStudiesControllerCreateBotPostMutation,
  useStudiesControllerGenerateBotInteractionMutation,
  useStudiesControllerGenerateFollowingActionMutation,
} from "../../../services/api-service-sub-services/studies-api-service";
import { useAppSelector } from "../../../store/store";
import { selectUserStudyId } from "../../../store/user.slice";

type BotEngagementRunnerContextProps = {
  initialize?: () => void;
};

const BotEngagementRunnerContext =
  createContext<BotEngagementRunnerContextProps>({});

const CREATE_CONNECTION_INTERVAL = 244; // 244 seconds
const CREATE_POST_INTERACTION_INTERVAL = 73; // 73 seconds
const CREATE_COMMENT_INTERACTION_INTERVAL = 103; //103 seconds
const CREATE_BOT_POST_INTERVAL = 130; // 130 seconds
const CREATE_BOT_COMMENT_INTERVAL = 65; // 65 seconds

export const BotEngagementRunnerProvider = (props: PropsWithChildren) => {
  const studyId = useAppSelector(selectUserStudyId);

  const [generateFollowing] =
    useStudiesControllerGenerateFollowingActionMutation({
      selectFromResult: () => ({}),
    });

  const [generateInteraction] =
    useStudiesControllerGenerateBotInteractionMutation({
      selectFromResult: () => ({}),
    });

  const [createBotPost] = useStudiesControllerCreateBotPostMutation({
    selectFromResult: () => ({}),
  });

  const [createBotComment] = useStudiesControllerCreateBotCommentMutation({
    selectFromResult: () => ({}),
  });

  const timer = useTimer({ delay: 1000, startImmediately: false }, async () => {
    const elapsedSeconds = lodash.floor(
      timer.getElapsedStartedTime() / 1000,
      0
    );

    if (!studyId) return;

    if (elapsedSeconds % CREATE_CONNECTION_INTERVAL === 0) {
      await generateFollowing({
        id: studyId,
      }).unwrap();
    }

    if (elapsedSeconds % CREATE_POST_INTERACTION_INTERVAL === 0) {
      await generateInteraction({
        id: studyId,
        createBotInteractionDto: {
          // @ts-ignore
          type: "post",
        },
      }).unwrap();
    }

    if (elapsedSeconds % CREATE_COMMENT_INTERACTION_INTERVAL === 0) {
      await generateInteraction({
        id: studyId,
        createBotInteractionDto: {
          // @ts-ignore
          type: "comment",
        },
      }).unwrap();
    }

    if (elapsedSeconds % CREATE_BOT_POST_INTERVAL === 0) {
      await createBotPost({
        id: studyId,
      }).unwrap();
    }

    if (elapsedSeconds % CREATE_BOT_COMMENT_INTERVAL === 0) {
      await createBotComment({
        id: studyId,
      }).unwrap();
    }
  });

  const initialize = () => {
    timer.start();
  };

  return (
    <BotEngagementRunnerContext.Provider value={{ initialize }}>
      {props.children}
    </BotEngagementRunnerContext.Provider>
  );
};

export const useBotEngagementRunnerContext = () =>
  useContext(BotEngagementRunnerContext);
