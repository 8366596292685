import React, { Fragment, useCallback, useRef, useState } from "react";
import {
  Box,
  Button,
  HStack,
  PresenceTransition,
  Spinner,
  Text,
  View,
  VStack,
} from "native-base";
import { PostComponent } from "../posts/components/post/post.component";
import { PostReturnDto } from "../../services/api-service-sub-services/studies-api-service";
import { Platform, TouchableOpacity } from "react-native";
import { useAppSelector } from "../../store/store";
import { selectUserProfile } from "../../store/user.slice";
import { PostContainer } from "../posts/post.container";
import { FlashList, ListRenderItemInfo } from "@shopify/flash-list";
import _ from "lodash";
import { AntDesign } from "@expo/vector-icons";
import { useScrollToTop } from "@react-navigation/native";
import { NewPostPromptSmall } from "../posts/components/post/new-post-prompt-small";

const Post = PostContainer(PostComponent);

export type FeedScreenProps = {
  posts?: PostReturnDto[];
  numberOfNewPosts: number;
  isFetchingPosts: boolean;
  isRefreshing: boolean;
  onRefresh: () => void;
  onBottomReached: () => Promise<void>;
  onNewPostButtonPress: () => void;
};

export const FeedScreen = ({
  isFetchingPosts,
  onBottomReached,
  numberOfNewPosts,
  ...props
}: FeedScreenProps) => {
  const userProfile = useAppSelector(selectUserProfile);
  const [isOnTopOfFeed, setIsOnTopOfFeed] = useState(true);

  // scroll to top when active tab is tapped
  const flashlistRef = useRef<FlashList<PostReturnDto> | null>(null);
  useScrollToTop(flashlistRef as any);

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<PostReturnDto>) => {
      return <Post hideCommentSection={true} post={item} />;
    },
    []
  );

  /**
   * Renders the header of the Flashlist.
   * This doesn't render the header in the web.
   */
  const renderHeader = useCallback(() => {
    if (!numberOfNewPosts) return null;
    if (Platform.OS === "web") return null;

    return (
      <View
        py={1}
        mb={3}
        shadow={1}
        borderRadius="xl"
        overflow="hidden"
        bg="primary"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="white">
          Pull to show {numberOfNewPosts} new post{numberOfNewPosts > 1 && "s"}
        </Text>
      </View>
    );
  }, [numberOfNewPosts, Platform]);

  /**
   * Renders the footer of the Flashlist.
   * This just shows a spinner indicating the current loading state.
   */
  const renderFooter = useCallback(() => {
    return (
      <Box h={8} display="flex" justifyContent="center">
        {isFetchingPosts ? <Spinner /> : null}
      </Box>
    );
  }, [isFetchingPosts]);

  const renderItemSeparator = useCallback(() => {
    return <View height="3" />;
  }, []);

  const debounceOnEndReached = useCallback(
    _.debounce(() => onBottomReached(), 300),
    [onBottomReached]
  );

  return (
    <Fragment>
      <VStack
        variant="scrollable-screen"
        flex="1"
        display="flex"
        safeAreaTop
        backgroundColor="xLightGrey"
      >
        <Box mx={3} pb={3}>
          {/*Displays the Share your thoughts here section*/}
          <NewPostPromptSmall
            onPress={props.onNewPostButtonPress}
            profile={userProfile}
          />
        </Box>

        {Platform.OS === "web" && numberOfNewPosts > 0 && (
          <TouchableOpacity onPress={props.onRefresh}>
            <Box bg="primary" alignItems="center" justifyContent="center">
              <Text color="white">
                Load {numberOfNewPosts} new post
                {numberOfNewPosts > 1 && "s"}
              </Text>
            </Box>
          </TouchableOpacity>
        )}

        <View
          flex="1"
          backgroundColor="xLightGrey"
          position="relative"
          borderTopRadius="xl"
          overflow="hidden"
          marginX={3}
        >
          {!!props.posts?.length && (
            <FlashList
              showsVerticalScrollIndicator={false}
              ref={flashlistRef}
              data={props.posts}
              contentContainerStyle={{
                paddingTop: !!numberOfNewPosts ? 0 : 12,
                paddingBottom: 16,
              }}
              keyboardShouldPersistTaps="handled"
              keyboardDismissMode="on-drag"
              automaticallyAdjustKeyboardInsets
              keyExtractor={({ _id }) => _id}
              maintainVisibleContentPosition={{ minIndexForVisible: 0 }}
              ItemSeparatorComponent={renderItemSeparator}
              renderItem={renderItem}
              ListHeaderComponent={renderHeader}
              onEndReached={debounceOnEndReached}
              ListFooterComponentStyle={{
                paddingBottom: 8,
                backgroundColor: "xLightGrey",
                display: "flex",
                alignItems: "center",
              }}
              estimatedItemSize={510}
              onRefresh={props.onRefresh}
              refreshing={props.isRefreshing}
              onScroll={(e) =>
                setIsOnTopOfFeed(e.nativeEvent.contentOffset.y <= 40)
              }
              scrollEventThrottle={16}
              // Don't render unless there's at least 1 item
              // Otherwise it will start at the bottom
              {...(props.posts?.length && {
                ListFooterComponent: renderFooter,
              })}
            />
          )}

          <View
            position="absolute"
            top={2}
            w="full"
            display="flex"
            alignItems="center"
          >
            <PresenceTransition
              visible={!isOnTopOfFeed && numberOfNewPosts > 1}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 250 } }}
            >
              <Button
                p={0}
                h={8}
                w={32}
                bg="primary"
                onPress={() => {
                  flashlistRef.current?.scrollToOffset({
                    offset: 0,
                    animated: true,
                  });
                  props.onRefresh();
                }}
              >
                <HStack space={1} alignItems="center" justifyContent="center">
                  <AntDesign name="arrowup" size={16} color="white" />
                  <Text color="white">New Posts</Text>
                </HStack>
              </Button>
            </PresenceTransition>
          </View>
        </View>
      </VStack>
    </Fragment>
  );
};
