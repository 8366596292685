import { CommentReturnDto } from "../../../services/api-service-sub-services/studies-api-service";

/**
 * Recursively filters out comments whose 'date' is in the future
 * @param items The array of tree items
 * @param cutoffDate The current time
 * @returns A new array of tree items, excluding future-dated items (and their children)
 */
export const removeFutureComments = (
  items: CommentReturnDto[],
  cutoffDate: Date
): CommentReturnDto[] => {
  return items
    .filter((item) => {
      const itemDate = new Date(item.date);
      // Keep this item only if date <= now
      return itemDate <= cutoffDate;
    })
    .map((item) => {
      // Recursively filter the children, if any
      const filteredChildren = item.children
        ? removeFutureComments(item.children, cutoffDate)
        : [];

      // Return a copy of the item with filtered children
      return {
        ...item,
        children: filteredChildren,
      };
    });
};

/**
 * Recursively counts the total number of items in a tree structure
 * @param items - The array of tree items
 * @returns The total count of items (including all nested children)
 */
export const countTotalThreadedComments = (
  items: CommentReturnDto[]
): number => {
  return items.reduce((acc, item) => {
    const childCount = item.children
      ? countTotalThreadedComments(item.children)
      : 0;
    return acc + 1 + childCount; // +1 for this item, plus all of its children
  }, 0);
};
