import React, { useCallback, useState } from "react";
import { Box, FlatList, HStack, View, VStack } from "native-base";
import {
  CommentReturnDto,
  ParticipantProfileWithPropertiesDto,
} from "../../../../services/api-service-sub-services/studies-api-service";
import { OpenCreateNewCommentSheetParams } from "../../hooks/use-open-comment-bottom-sheet.hook";
import { OnCommentInteractionButtonPressArgs } from "../../post.container";
import { useNavigation } from "@react-navigation/native";
import { Routes } from "../../../../navigation/routes";
import { CommentThreadItemHeader } from "./comment-thread-item-header.component";
import { CommentContent } from "./comment-content.component";
import { CommentActions } from "./comment-actions.component";
import { getThreadedCommentsCount } from "../../../core/utils/comment.util";
import { SmallActionButton } from "../../../core/components/small-action-button.component";
import { MaterialIcons } from "@expo/vector-icons";
import { ThreadedComments } from "./threaded-comments.component";
import { useDwellTimeContext } from "../../../core/context/dwell-time-tracker.context";
import {
  RectDimensionsState,
  VisibilitySensor,
} from "../../../core/components/visibility-sensor.component";
import lodash from "lodash";
import { Dimensions } from "react-native";
import { events } from "../../../../constants/analytics.constants";
import { useTrackEvent } from "../../../core/hooks/use-track-event";

type Props = {
  postId: string;
  comments: CommentReturnDto[];
  isVisible: boolean;
  highlightedCommentId?: string;
  openCreateNewCommentSheet?: (
    params: OpenCreateNewCommentSheetParams
  ) => Promise<void>;
  onCommentInteractionButtonPress: (
    args: OnCommentInteractionButtonPressArgs
  ) => Promise<void>;
  isThreadedCommentsEnabled?: boolean;
};

type CommentRenderItemProps = {
  isThreadedCommentsEnabled: boolean;
  comment: CommentReturnDto;
  openCreateNewCommentSheet?: (
    params: OpenCreateNewCommentSheetParams
  ) => Promise<void>;
  onCommentInteractionButtonPress: (
    args: OnCommentInteractionButtonPressArgs
  ) => Promise<void>;
};

const CommentRenderItem = React.memo((props: CommentRenderItemProps) => {
  const { addEntryForSeenComment, removeEntryForSeenComment } =
    useDwellTimeContext();
  const navigation = useNavigation();

  const handleProfilePress = (
    profile: ParticipantProfileWithPropertiesDto | string = props.comment
      .profile
  ) => {
    // @ts-ignore
    navigation.navigate(Routes.APP_STACK__BOT_PROFILE, {
      profile: profile,
    });
  };

  const track = useTrackEvent();

  const [isCommentThreadsVisible, setIsCommentsThreadVisible] =
    useState<boolean>(false);

  const trackVisibleCommentLayoutPosition = lodash.debounce(
    useCallback(
      (comment: CommentReturnDto, rectDimensions: RectDimensionsState) => {
        const { height: screenHeight, width: screenWidth } =
          Dimensions.get("window");
        track(events.commentPositionMeasured, {
          commentId: comment._id,
          commentLeftPosition: rectDimensions.rectLeft,
          commentRightPosition: rectDimensions.rectRight,
          commentTopPosition: rectDimensions.rectTop,
          commentBottomPosition: rectDimensions.rectBottom,
          commentHeight: rectDimensions.rectHeight,
          commentWidth: rectDimensions.rectWidth,
          screenHeight: screenHeight,
          screenWidth: screenWidth,
        });
      },
      []
    ),
    250
  );

  return (
    <VisibilitySensor
      triggerOnce={false}
      onChange={(isVisible) => {
        if (isVisible) {
          addEntryForSeenComment(props.comment._id);
        } else {
          removeEntryForSeenComment(props.comment._id);
        }
      }}
      onVisibleContentLayoutChange={(rectDimensions) => {
        trackVisibleCommentLayoutPosition(props.comment, rectDimensions);
      }}
    >
      <HStack
        space="3"
        alignItems="flex-start"
        backgroundColor="xLightGrey"
        borderRadius="xl"
      >
        <VStack flex="1" borderRadius="xl" p="3" space={1}>
          <CommentThreadItemHeader
            date={props.comment.date}
            profile={props.comment.profile}
          />

          <CommentContent
            content={props.comment.content}
            mentionedProfiles={props.comment.taggedProfiles}
            onMentionedProfilePress={handleProfilePress}
          />

          <HStack justifyContent="space-between">
            <CommentActions
              interactionsCounter={{
                like: props.comment.likes || 0,
                dislike: props.comment.dislikes || 0,
                ...props.comment.customInteractionsCount,
              }}
              activeInteraction={props.comment.interactionByMe}
              onCommentInteractionButtonPress={async (interaction: string) => {
                await props.onCommentInteractionButtonPress({
                  commentId: props.comment._id,
                  interaction: interaction,
                });
              }}
              commentCounter={getThreadedCommentsCount(props.comment)}
              isCommentsButtonActive={isCommentThreadsVisible}
              onCommentActionButtonPress={() => {
                setIsCommentsThreadVisible((prev) => !prev);
              }}
              hideCommentButton={!props.isThreadedCommentsEnabled}
            />
            {props.isThreadedCommentsEnabled && (
              <HStack marginLeft={-2}>
                <SmallActionButton
                  onPress={() => {
                    props.openCreateNewCommentSheet?.({
                      profile: props.comment.profile,
                      content: props.comment.content,
                      parentCommentId: props.comment._id,
                    });
                  }}
                  icon={<MaterialIcons name="reply" size={18} />}
                  counterValue="Reply"
                />
              </HStack>
            )}
          </HStack>

          {isCommentThreadsVisible && (
            <Box marginTop={3}>
              <ThreadedComments
                disabled={!props.isThreadedCommentsEnabled}
                onCommentInteractionButtonPress={
                  props.onCommentInteractionButtonPress
                }
                onCreateCommentReply={props.openCreateNewCommentSheet}
                profile={props.comment.profile}
                childComments={props.comment.children || []}
              />
            </Box>
          )}
        </VStack>
      </HStack>
    </VisibilitySensor>
  );
});

export const PostCommentsComponent = ({
  highlightedCommentId,
  ...props
}: Props) => {
  // if it's not set as visible
  if (!props.isVisible) {
    return null;
  }

  return (
    <Box mt={5} px={3}>
      <FlatList
        data={props.comments}
        scrollEnabled={false}
        keyExtractor={({ _id }) => _id}
        ItemSeparatorComponent={() => <View height="3" />}
        renderItem={({ item }) => (
          <CommentRenderItem
            isThreadedCommentsEnabled={props.isThreadedCommentsEnabled || false}
            comment={item}
            openCreateNewCommentSheet={props.openCreateNewCommentSheet}
            onCommentInteractionButtonPress={
              props.onCommentInteractionButtonPress
            }
          />
        )}
      />
    </Box>
  );
};
