import { Text } from "native-base";
import { LinkPreview } from "@flyerhq/react-native-link-preview";
import React from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { Routes } from "../../../../navigation/routes";
import { AppStackParamList } from "../../../../navigation/navigators/app-stack.navigator";
import { useReactivePopupsSideEffect } from "../../../core/hooks/use-reactive-popups-side-effect";

export type LinkPreviewComponentProps = {
  url: string;
};

export const LinkPreviewComponent = (props: LinkPreviewComponentProps) => {
  const navigation = useNavigation<NavigationProp<AppStackParamList>>();
  const { triggerPopupSideEffect } = useReactivePopupsSideEffect();

  return (
    <LinkPreview
      enableAnimation
      text={props.url}
      textContainerStyle={{
        marginLeft: 20,
        marginRight: 20,
        marginTop: 0,
      }}
      touchableWithoutFeedbackProps={{
        onPress: async () => {
          navigation.navigate(Routes.APP_STACK__IN_APP_BROWSER, {
            url: props.url,
          });
          await triggerPopupSideEffect("when-user-opens-a-link", {
            link: props.url,
          });
        },
      }}
      metadataContainerStyle={{}}
      containerStyle={{
        backgroundColor: "rgba(23, 128, 251, 0.05)",
      }}
      renderTitle={(title) => {
        return (
          <Text variant="heading" color="black">
            {title}
          </Text>
        );
      }}
      renderHeader={(header) => {
        return <Text>{header}</Text>;
      }}
      renderDescription={(description) => {
        return (
          <Text variant="small" color="text-black" mt="2">
            {description}
          </Text>
        );
      }}
      renderText={() => null}
    />
  );
};
