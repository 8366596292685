import { HStack, Image } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import React from "react";
import { useTreatmentArmExperience } from "../../../core/hooks/use-treatment-arm-experience";
import { SmallActionButton } from "../../../core/components/small-action-button.component";

export type CommentActionsProps = {
  size?: number;
  onCommentActionButtonPress: () => void;
  isCommentsButtonActive: boolean;
  commentCounter: number;
  hideCommentButton?: boolean;
  onCommentInteractionButtonPress: (interaction: string) => Promise<void>;
  activeInteraction?: string;
  interactionsCounter: Record<string, number>;
};

export const CommentActions = (props: CommentActionsProps) => {
  const treatmentArmExperience = useTreatmentArmExperience();
  const { size = 16 } = props;

  const isLikesEnabled =
    treatmentArmExperience.interactions.isCommentLikesEnabled;
  const isDislikesEnabled =
    treatmentArmExperience.interactions.isCommentDislikesEnabled;

  return (
    <HStack alignItems="center" space={1}>
      {/** custom interactions */}
      {treatmentArmExperience.interactions.customCommentInteractions.map(
        (interaction) => {
          return (
            <SmallActionButton
              isActive={
                props.activeInteraction === interaction.name + ".comment"
              }
              onPress={() =>
                props.onCommentInteractionButtonPress(
                  interaction.name + ".comment"
                )
              }
              key={interaction._id}
              counterValue={
                props.interactionsCounter[interaction.name + ".comment"] || 0
              }
              icon={
                <Image
                  src={interaction.icon}
                  height={`${size}px`}
                  width={`${size}px`}
                  alt="interaction"
                />
              }
            />
          );
        }
      )}

      {/** like */}
      {isLikesEnabled && (
        <SmallActionButton
          isActive={props.activeInteraction === "like"}
          onPress={() => props.onCommentInteractionButtonPress("like")}
          counterValue={props.interactionsCounter["like"] || 0}
          icon={<AntDesign name="like2" size={size} />}
        />
      )}

      {/** dislike */}
      {isDislikesEnabled && (
        <SmallActionButton
          isActive={props.activeInteraction === "dislike"}
          onPress={() => props.onCommentInteractionButtonPress("dislike")}
          icon={<AntDesign name="dislike2" size={size} />}
          counterValue={props.interactionsCounter["dislike"] || 0}
        />
      )}

      {/** comment */}
      {treatmentArmExperience.interactions.isCommentsEnabled &&
        props.commentCounter > 0 &&
        !props.hideCommentButton && (
          <SmallActionButton
            onPress={props.onCommentActionButtonPress}
            icon={<AntDesign name="message1" size={size} />}
            counterValue={props.commentCounter}
            isActive={props.isCommentsButtonActive}
          />
        )}
    </HStack>
  );
};
