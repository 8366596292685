import React, { useCallback } from "react";
import { PostComponentProps } from "./components/post/post.component";
import { useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import {
  ParticipantProfileWithPropertiesDto,
  useStudiesControllerFindPostByIdQuery,
  useStudiesControllerUpsertInteractionForCommentMutation,
  useStudiesControllerUpsertInteractionForPostMutation,
} from "../../services/api-service-sub-services/studies-api-service";
import { useNavigation } from "@react-navigation/native";
import { useTreatmentArmExperience } from "../core/hooks/use-treatment-arm-experience";
import { Routes } from "../../navigation/routes";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { useReactivePopupsSideEffect } from "../core/hooks/use-reactive-popups-side-effect";
import { useOpenCommentBottomSheet } from "./hooks/use-open-comment-bottom-sheet.hook";
import { logger } from "../core/utils/logger.util";
import { events } from "../../constants/analytics.constants";
import { usePostInteractions } from "./hooks/use-post-interactions";
import { useGetPostComments } from "./hooks/use-get-post-comments";

// TODO: REFACTOR THESE
export type PostContainerProps = Pick<
  PostComponentProps,
  "post" | "isCommentSectionVisibleByDefault" | "highlightedCommentId"
> & {
  hideCommentSection?: boolean;
};

export type Interaction = {
  [key: string]: number;
};

export type OnCommentInteractionButtonPressArgs = {
  interaction: string;
  commentId: string;
};

export const PostContainer = (Component: React.FC<PostComponentProps>) => {
  return function _(props: PostContainerProps) {
    const track = useTrackEvent();
    const navigation = useNavigation();

    const studyId = useAppSelector(selectUserStudyId);
    const userProfile = useAppSelector(selectUserProfile);
    const treatmentArmExperience = useTreatmentArmExperience();
    const { triggerPopupSideEffect } = useReactivePopupsSideEffect();

    const [saveInteraction, { isLoading: isSavingInteraction }] =
      useStudiesControllerUpsertInteractionForPostMutation({
        selectFromResult: ({ isLoading }) => ({ isLoading }),
      });

    const [saveCommentInteraction, { isLoading: isSavingCommentInteraction }] =
      useStudiesControllerUpsertInteractionForCommentMutation({
        selectFromResult: ({ isLoading }) => ({ isLoading }),
      });

    const { data: post, refetch: refetchPost } =
      useStudiesControllerFindPostByIdQuery(
        { id: studyId!, postId: props.post._id },
        {
          skip: !studyId,
          pollingInterval: 1000,
          selectFromResult: ({ data }) => ({ data }),
        }
      );

    const [{ comments, commentCount }, { refetchComments }] =
      useGetPostComments({
        postId: props.post._id,
      });

    const {
      counters: interactionCounters,
      activeInteraction: currentInteraction,
    } = usePostInteractions(post);

    const handleCommentInteractionButtonPress = async (
      args: OnCommentInteractionButtonPressArgs
    ) => {
      if (!studyId || !userProfile || isSavingCommentInteraction) {
        return;
      }

      try {
        await saveCommentInteraction({
          id: studyId,
          commentId: args.commentId,
          createInteractionDto: {
            action: args.interaction,
            profile: userProfile._id,
            // TODO remove this property in the future since this is no longer needed
            affiliation: "",
          },
        }).unwrap();

        track(events.userAddedInteractionToComment, {
          interaction: args.interaction,
          commentId: args.commentId,
        });
      } catch (e) {
        logger.error(e, {
          message: "Failed to submit comment interaction",
          interaction: args.interaction,
        });
      }
    };

    const onInteractionButtonPress = async (interaction: string) => {
      if (!studyId || !userProfile || isSavingInteraction) {
        return;
      }
      try {
        await saveInteraction({
          id: studyId,
          postId: props.post._id,
          createInteractionDto: {
            action: interaction,
            profile: userProfile._id,
            // TODO remove this property in the future since this is no longer needed
            affiliation: "",
          },
        }).unwrap();

        await triggerPopupSideEffect("when-user-engages-post", {
          postId: props.post._id,
        });

        track(events.userAddedInteractionToPost, {
          interaction: interaction,
          postId: props.post._id,
        });

        await triggerPopupSideEffect("when-user-engages-post");

        await refetchPost();
      } catch (e) {
        logger.error("Interaction failed to submit", e);
      }
    };

    const handleOnProfilePress = useCallback(
      (profile?: string | ParticipantProfileWithPropertiesDto) => {
        // @ts-ignore
        navigation.navigate(Routes.APP_STACK__BOT_PROFILE, {
          profile: profile,
        });
      },
      [props.post.profile, navigation]
    );

    const [openCreateNewCommentSheet] = useOpenCommentBottomSheet({
      onSuccessCallback: () => {
        refetchComments();
      },
      postId: props.post._id,
    });

    const handleCommentButtonPress = () => {
      if (!userProfile || !studyId) {
        return;
      }

      track(events.userOpenedSinglePost, {
        postId: props.post._id,
      });

      // @ts-ignore
      navigation.navigate(Routes.APP_STACK__SINGLE_POST, {
        postId: props.post._id,
      });
    };

    return (
      <Component
        hideCommentSection={props.hideCommentSection}
        customInteractions={
          treatmentArmExperience.interactions.customInteractions
        }
        onProfilePress={handleOnProfilePress}
        interaction={interactionCounters}
        currentInteraction={currentInteraction}
        highlightedCommentId={props.highlightedCommentId}
        isCommentSectionVisibleByDefault={
          props.isCommentSectionVisibleByDefault
        }
        post={post ?? props.post}
        isInteractionsLoading={isSavingInteraction}
        comments={comments}
        totalComments={commentCount}
        onInteractionButtonPress={onInteractionButtonPress}
        onCommentInteractionButtonPress={handleCommentInteractionButtonPress}
        openCreateNewCommentSheet={openCreateNewCommentSheet}
        onCommentButtonPress={handleCommentButtonPress}
      />
    );
  };
};
