import React from "react";
import { Notification } from "../../services/api-service-sub-services/notifications-api-service";
import { Circle, HStack, Text, VStack } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { formatDateTime } from "../core/utils/date.util";

interface Props {
  notification: Notification;
  isSeen?: Boolean;
}

export const NotificationItemComponent: React.FC<Props> = (props) => {
  return (
    <VStack
      opacity={props.isSeen ? 0.8 : 1}
      borderBottomColor="lightGrey"
      borderBottomWidth="1"
      px="4"
      py="4"
      space="2"
    >
      <VStack>
        <HStack alignItems="center" flex="1">
          {/** notification seen indicator */}
          {!props.isSeen && <Circle width="2" h="2" bg="primary" mr="2" />}

          {/** notification title */}
          <Text
            noOfLines={1}
            isTruncated
            maxWidth="100%"
            color="black"
            bold
            variant="body1"
          >
            {props.notification.title}
          </Text>
        </HStack>

        <HStack space="1" alignItems="center" justifyContent="flex-start">
          <AntDesign name="calendar" size={16} color="#1F2330" />
          <Text variant="body2" color="text-black">
            {formatDateTime(props.notification.date)}
          </Text>
        </HStack>
      </VStack>

      <Text noOfLines={2} isTruncated variant="body1">
        {props.notification.body}
      </Text>
    </VStack>
  );
};
