import {
  CommentReturnDto,
  useStudiesControllerFindPostCommentsByPostIdQuery,
} from "../../../services/api-service-sub-services/studies-api-service";
import { useAppSelector } from "../../../store/store";
import { selectUserStudyId } from "../../../store/user.slice";
import { useTreatmentArmExperience } from "../../core/hooks/use-treatment-arm-experience";
import {
  countTotalThreadedComments,
  removeFutureComments,
} from "../utils/comments.util";
import { useCallback, useEffect, useMemo, useState } from "react";

type UseGetPostComments = {
  postId: string;
};

type UseGetPostCommentsReturn = [
  {
    commentCount: number;
    comments: CommentReturnDto[];
  },
  { refetchComments: () => Promise<void> }
];

export const useGetPostComments = (
  params: UseGetPostComments
): UseGetPostCommentsReturn => {
  const studyId = useAppSelector(selectUserStudyId);
  const treatmentArmExperience = useTreatmentArmExperience();
  const [currentTime, setCurrentTime] = useState(() => new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      refetch();
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const { data, refetch } = useStudiesControllerFindPostCommentsByPostIdQuery(
    { id: studyId as string, postId: params.postId, limit: 100 },
    {
      selectFromResult: ({ data }) => ({ data }),
    }
  );

  const comments = useMemo(() => {
    return removeFutureComments(data?.docs || [], currentTime);
  }, [data?.docs, currentTime]);

  const commentCount = useMemo(() => {
    return treatmentArmExperience.interactions.isThreadedCommentsEnabled
      ? countTotalThreadedComments(comments)
      : comments.length;
  }, [
    comments,
    treatmentArmExperience.interactions.isThreadedCommentsEnabled,
    currentTime,
  ]);

  const handleRefetchComments = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const result = useMemo(
    () => ({ commentCount, comments }),
    [commentCount, comments]
  );

  const actions = useMemo(
    () => ({ refetchComments: handleRefetchComments }),
    [handleRefetchComments]
  );

  return [result, actions];
};
