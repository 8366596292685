import { PostReturnDto } from "../../../services/api-service-sub-services/studies-api-service";

/**
 * Computes and returns the interaction counts for a post.
 *
 * This hook extracts the number of likes, dislikes, and any custom interactions from the given post.
 * If the post or any of its properties are undefined, default values are used.
 *
 * @param {PostReturnDto} [post] - The post object containing interaction details.
 * @param {number} [post.likes] - The number of likes on the post.
 * @param {number} [post.dislikes] - The number of dislikes on the post.
 * @param {Object.<string, number>} [post.customInteractionsCount] - An object with custom interaction counts keyed by interaction type.
 * @param {string} [post.interactionByMe] - The type of interaction performed by the current user.
 * @returns {{
 *   activeInteraction: string,
 *   counters: {
 *     like: number,
 *     dislike: number,
 *     [interaction: string]: number
 *   }
 * }} An object containing:
 *   - `activeInteraction`: The current user's interaction (or "none" if not specified).
 *   - `counters`: An object with counts for likes, dislikes, and any additional custom interactions.
 */
export const usePostInteractions = (post?: PostReturnDto) => {
  const like = post?.likes || 0;
  const dislike = post?.dislikes || 0;
  const customInteractions = post?.customInteractionsCount || {};
  const activeInteraction = post?.interactionByMe ?? "none";

  return {
    activeInteraction,
    counters: {
      like,
      dislike,
      ...customInteractions,
    },
  };
};
