import { createContext, PropsWithChildren, useContext, useRef } from "react";
import dayjs from "dayjs";
import { useTrackEvent } from "../hooks/use-track-event";
import { events } from "../../../constants/analytics.constants";

type DwellTimeTrackerContextType = {
  addEntryForSeenComment: (id: string) => void;
  removeEntryForSeenComment: (id: string) => void;
  addEntryForSeenPost: (id: string) => void;
  removeEntryForSeenPost: (id: string) => void;
};

const DwellTimeTrackerContext = createContext<DwellTimeTrackerContextType>({
  addEntryForSeenComment: () => {},
  removeEntryForSeenComment: () => {},
  addEntryForSeenPost: () => {},
  removeEntryForSeenPost: () => {},
});

export const DwellTimeTrackerProvider = (props: PropsWithChildren) => {
  const track = useTrackEvent();

  const commentsDwellTimeMap = useRef(new Map());
  const postsDwellTimeMap = useRef(new Map());

  const addEntryForSeenPost = (postId: string) => {
    const now = dayjs();
    track(events.postEnteredViewport, {
      postId: postId,
      timestamp: now.utc().toISOString(),
    });
    postsDwellTimeMap.current.set(postId, now.toISOString());
  };

  const removeEntryForSeenPost = (postId: string) => {
    const now = dayjs();
    track(events.postExitedViewport, {
      postId: postId,
      timestamp: now.utc().toISOString(),
    });
    const post = postsDwellTimeMap.current.get(postId);
    if (!post) {
      return;
    }

    const diff = now.diff(dayjs(post), "milliseconds");
    if (diff > 100) {
      track(events.postDwellTimeMeasured, {
        postId: postId,
        dwellTime: diff,
      });
    }
  };

  const addEntryForSeenComment = (commentId: string) => {
    const now = dayjs();
    track(events.commentEnteredViewport, {
      commentId: commentId,
      timestamp: now.utc().toISOString(),
    });
    commentsDwellTimeMap.current.set(commentId, now.toISOString());
  };

  const removeEntryForSeenComment = (commentId: string) => {
    const now = dayjs();
    track(events.commentExitedViewport, {
      commentId: commentId,
      timestamp: now.utc().toISOString(),
    });

    // get the comment
    const comment = commentsDwellTimeMap.current.get(commentId);
    commentsDwellTimeMap.current.delete(commentId);

    if (!comment) {
      return;
    }

    const diff = now.diff(dayjs(comment), "milliseconds");
    if (diff > 100) {
      track(events.commentDwellTimeMeasured, {
        commentId: commentId,
        dwellTime: diff,
      });
    }
  };

  return (
    <DwellTimeTrackerContext.Provider
      value={{
        addEntryForSeenComment,
        removeEntryForSeenComment,
        addEntryForSeenPost,
        removeEntryForSeenPost,
      }}
    >
      {props.children}
    </DwellTimeTrackerContext.Provider>
  );
};

export const useDwellTimeContext = () => useContext(DwellTimeTrackerContext);
