import React from "react";
import WebView from "react-native-webview";
import Constants from "expo-constants";
import IframeRenderer, { iframeModel } from "@native-html/iframe-plugin";
import RenderHTML from "react-native-render-html";
import { Dimensions } from "react-native";
import { useTheme } from "native-base";

export type HtmlRendererProps = {
  htmlContent: string;
  modalWidth?: number;
  handleLinkPress: (url: string) => void;
};

export const HtmlRenderer = React.memo((props: HtmlRendererProps) => {
  const { width } = Dimensions.get("window");
  const theme = useTheme();
  return (
    <RenderHTML
      classesStyles={{
        "ql-align-right": {
          textAlign: "right",
        },
        "ql-align-center": {
          textAlign: "center",
        },
        "ql-align-justify": {
          textAlign: "justify",
        },
        "ql-size-small": {
          fontSize: 12,
        },
        "ql-size-large": {
          fontSize: 18,
        },
        "ql-size-huge": {
          fontSize: 24,
        },
        "ql-size-normal": {
          fontSize: 14,
        },
      }}
      WebView={WebView}
      contentWidth={width * 0.95 - 10}
      source={{
        html: props.htmlContent,
      }}
      baseStyle={{
        color: theme.colors.black as unknown as string,
      }}
      ignoredDomTags={["br"]}
      enableCSSInlineProcessing={true}
      enableExperimentalBRCollapsing={true}
      enableExperimentalGhostLinesPrevention={true}
      systemFonts={[...Constants.systemFonts]}
      tagsStyles={{
        p: {
          marginBottom: 8,
          marginTop: 8,
          fontSize: 14,
        },
        span: {
          fontSize: 14,
        },
        img: {
          // @ts-expect-error - properly define the theme provider
          backgroundColor: theme.colors.darkGrey,
          objectFit: "contain",
          borderRadius: 8,
          overflow: "hidden",
        },
      }}
      renderers={{
        iframe: IframeRenderer,
      }}
      customHTMLElementModels={{
        iframe: iframeModel,
      }}
      renderersProps={{
        img: {
          enableExperimentalPercentWidth: true,
          initialDimensions: {
            height: 100,
            width: props.modalWidth ?? width,
          },
        },
        iframe: {
          scalesPageToFit: true,
          webViewProps: {},
        },
        a: {
          onPress: (event, href) => {
            props.handleLinkPress(href);
          },
        },
      }}
    />
  );
});
