import { api } from "../api-service";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    usersControllerCreateUser: build.mutation<
      UsersControllerCreateUserApiResponse,
      UsersControllerCreateUserRequest
    >({
      query: (queryArg) => ({
        url: `/users`,
        method: "POST",
        body: queryArg.createUserDto,
      }),
    }),
    usersControllerGetUsers: build.query<
      UsersControllerGetUsersApiResponse,
      UsersControllerGetUsersRequest
    >({
      query: (queryArg) => ({
        url: `/users`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          search: queryArg.search,
          role: queryArg.role,
        },
      }),
    }),
    usersControllerUpdateUser: build.mutation<
      UsersControllerUpdateUserApiResponse,
      UsersControllerUpdateUserRequest
    >({
      query: (queryArg) => ({
        url: `/users`,
        method: "PATCH",
        body: queryArg.updateUserDto,
      }),
    }),
    usersControllerGetProfile: build.query<
      UsersControllerGetProfileApiResponse,
      UsersControllerGetProfileRequest
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/profile`,
        params: { study: queryArg.study },
      }),
    }),
    usersControllerCreateProfile: build.mutation<
      UsersControllerCreateProfileApiResponse,
      UsersControllerCreateProfileRequest
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/profile`,
        method: "POST",
        body: queryArg.createRealParticipantProfileDto,
      }),
    }),
    usersControllerUpdateProfile: build.mutation<
      UsersControllerUpdateProfileApiResponse,
      UsersControllerUpdateProfileRequest
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/profile`,
        method: "PUT",
        body: queryArg.createRealParticipantProfileDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UsersControllerCreateUserApiResponse =
  /** status 201 User created successfully */ SanitizedUserDto;
export type UsersControllerCreateUserRequest = {
  createUserDto: CreateUserDto;
};
export type UsersControllerGetUsersApiResponse =
  /** status 200 The requested paginated `SanitizedUserDTO` documents are returned. */ SanitizedUserDtoPaginatedReturn;
export type UsersControllerGetUsersRequest = {
  /** The page number to retrieve. */
  page?: number;
  /** The number of documents to retrieve. */
  limit?: number;
  search?: string;
  role?: "ADMIN" | "RESEARCHER" | "PARTICIPANT";
};
export type UsersControllerUpdateUserApiResponse =
  /** status 200 User updated successfully */ SanitizedUserDto;
export type UsersControllerUpdateUserRequest = {
  updateUserDto: UpdateUserDto;
};
export type UsersControllerGetProfileApiResponse =
  /** status 200  */ ParticipantProfileWithPropertiesDto;
export type UsersControllerGetProfileRequest = {
  id: string;
  study?: string;
};
export type UsersControllerCreateProfileApiResponse =
  /** status 201  */ ParticipantProfileWithPropertiesDto;
export type UsersControllerCreateProfileRequest = {
  id: string;
  createRealParticipantProfileDto: CreateRealParticipantProfileDto;
};
export type UsersControllerUpdateProfileApiResponse =
  /** status 200  */ ParticipantProfileValue[];
export type UsersControllerUpdateProfileRequest = {
  id: string;
  createRealParticipantProfileDto: CreateRealParticipantProfileDto;
};
export type SanitizedUserDto = {
  _id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  roles: ("ADMIN" | "RESEARCHER" | "PARTICIPANT")[];
  profilePicture?: string;
  currentStudy?: string;
  currentTreatmentArm?: string;
  university?: string;
  deviceIds?: string[];
  forgotPasswordCode?: string;
  forgotPasswordCodeExpiration?: string;
  createdAt: string;
  updatedAt: string;
};
export type CreateUserDto = {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  roles: ("ADMIN" | "RESEARCHER" | "PARTICIPANT")[];
  profilePicture?: string;
  currentStudy?: string;
  currentTreatmentArm?: string;
  university?: string;
  deviceIds?: string[];
  forgotPasswordCode?: string;
  forgotPasswordCodeExpiration?: string;
  invitationCode?: string;
  code?: string;
  participantCode?: string;
};
export type SanitizedUserDtoPaginatedReturn = {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page?: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: number;
  nextPage?: number;
  docs: SanitizedUserDto[];
};
export type ApiUnauthorizedResponseDto = {
  statusCode: number;
  message: string;
};
export type ApiNotFoundResponseDto = {
  statusCode: number;
  message: string;
};
export type UpdateUserDto = {
  email?: string;
  firstName?: string;
  lastName?: string;
  profilePicture?: string;
  university?: string;
  currentStudy?: string;
  currentTreatmentArm?: string;
};
export type PromptOverride = {
  prompt: string;
  botId: string;
  type: "post" | "comment" | "interaction";
};
export type ProfileValueEntity = {
  _id?: string;
  name:
    | "first-name"
    | "last-name"
    | "bio/description"
    | "location"
    | "political-affiliation"
    | "age"
    | "gender"
    | "race"
    | "household-income"
    | "profile-picture";
  value: string | number | boolean;
  type?: string;
};
export type PostLabel = {
  _id: string;
  study: string;
  title: string;
  isTitleHidden?: boolean;
  imageUrl?: string;
  color?: string;
  createdAt: string;
  updatedAt: string;
  duplicatedFromId?: string;
};
export type ParticipantProfileWithPropertiesDto = {
  _id: string;
  study: string;
  user?: string;
  treatmentArm?: string;
  tags?: string[];
  postPrompt?: string;
  commentPrompt?: string;
  interactionPrompt?: string;
  qualified: boolean;
  profileNumber?: string;
  participantCode?: string;
  followers: number;
  following: number;
  exitSurveySchedule?: string;
  finishedOnboardingSurveyAt?: string;
  enteredNewsfeedAt?: string;
  isAutoFollowingDisabled?: boolean;
  duplicatedFromId?: string;
  blockedProfiles?: string[];
  treatmentArmRestrictions?: object;
  promptOverrides?: PromptOverride[];
  properties: ProfileValueEntity[];
  labels?: PostLabel[];
  highlight?: PostLabel;
};
export type CreateRealParticipantProfileValuesDto = {
  value: string | number | boolean;
  name:
    | "first-name"
    | "last-name"
    | "bio/description"
    | "location"
    | "political-affiliation"
    | "age"
    | "gender"
    | "race"
    | "household-income"
    | "profile-picture";
};
export type CreateRealParticipantProfileDto = {
  properties: CreateRealParticipantProfileValuesDto[];
  study?: string;
};
export type ParticipantProfileValue = {
  _id: string;
  profile: string;
  name:
    | "first-name"
    | "last-name"
    | "bio/description"
    | "location"
    | "political-affiliation"
    | "age"
    | "gender"
    | "race"
    | "household-income"
    | "profile-picture";
  value: string | number | boolean;
  type?: string;
};
export const {
  useUsersControllerCreateUserMutation,
  useUsersControllerGetUsersQuery,
  useLazyUsersControllerGetUsersQuery,
  useUsersControllerUpdateUserMutation,
  useUsersControllerGetProfileQuery,
  useLazyUsersControllerGetProfileQuery,
  useUsersControllerCreateProfileMutation,
  useUsersControllerUpdateProfileMutation,
} = injectedRtkApi;
