import { useTrackEvent } from "./use-track-event";
import { useFocusEffect } from "@react-navigation/native";
import { useCallback } from "react";

export const useTrackScreenFocus = (
  event: string,
  eventData?: Record<string, number | string | boolean>
) => {
  const track = useTrackEvent();

  useFocusEffect(
    useCallback(() => {
      track(event, eventData);
    }, [])
  );
};
