import React, { useEffect } from "react";
import {
  NavigationProp,
  useNavigation,
  useNavigationState,
} from "@react-navigation/native";
import _ from "lodash";
import { Routes } from "../../../../navigation/routes";
import {
  Notification,
  useNotificationsControllerFindAllForUserQuery,
} from "../../../../services/api-service-sub-services/notifications-api-service";
import { Pressable, useToast } from "native-base";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  selectUserProfile,
  selectUserStudyId,
} from "../../../../store/user.slice";
import { NotificationBubble } from "../../../notifications/notification-bubble.component";
import {
  notificationSlice,
  selectIsNotificationsEnabled,
  selectReceivedNotifIds,
} from "../../../../store/notification.slice";
import { AppStackParamList } from "../../../../navigation/navigators/app-stack.navigator";
import { selectNotificationFeatureConfiguration } from "../../../../store/app.slice";
import { useTrackEvent } from "../../hooks/use-track-event";
import { events } from "../../../../constants/analytics.constants";

const LIMIT = 20;

export const useGlobalNotificationListener = () => {
  const routes = useNavigationState((state) => state.routes);
  const navigation = useNavigation<NavigationProp<AppStackParamList>>();
  const track = useTrackEvent();
  const notificationFeatureConfiguration = useAppSelector(
    selectNotificationFeatureConfiguration
  );

  const currentRoute = _.last(
    routes[routes.length - 1].state?.history
  ) as unknown as { key: string } | null;
  const isInNotificationScreens =
    currentRoute &&
    currentRoute.key
      .toLowerCase()
      .startsWith(Routes.APP_STACK__MAIN__NOTIFICATIONS);

  const isNotificationsEnabled = useAppSelector(selectIsNotificationsEnabled);
  const profile = useAppSelector(selectUserProfile);
  const studyId = useAppSelector(selectUserStudyId);
  const receivedNotifIds = useAppSelector(selectReceivedNotifIds);

  const dispatch = useAppDispatch();
  const toast = useToast();

  const { data } = useNotificationsControllerFindAllForUserQuery(
    { page: 1, limit: LIMIT },
    {
      skip: !isNotificationsEnabled || !!isInNotificationScreens,
      pollingInterval: 10000,
      selectFromResult: ({ data, isLoading }) => ({ data, isLoading }),
    }
  );

  const handleNotificationPopupPress = (notification: Notification) => {
    const notificationMeta = notification.meta as any;
    track(events.userOpenedNotification, {
      ...(notificationMeta?.postId && {
        postId: notificationMeta.postId,
      }),
      ...(notificationMeta?.commentId && {
        targetCommentId: notificationMeta.commentId,
      }),
    });
    // remove added badge count
    dispatch(notificationSlice.actions.decrementBadgeCount(1));
    // navigate to single post screen
    if (notificationMeta?.postId) {
      navigation.navigate(Routes.APP_STACK__SINGLE_POST, {
        postId: notificationMeta?.postId,
        highlightedCommentId: notificationMeta?.commentId,
      });
    }
    toast.close(notification._id);
  };

  useEffect(() => {
    if (
      !data?.docs ||
      notificationFeatureConfiguration?.status === "disabled"
    ) {
      return;
    }

    data.docs.forEach((notification) => {
      const isNotificationIncludedInInitialReceived = receivedNotifIds.includes(
        notification._id
      );
      if (!isNotificationIncludedInInitialReceived) {
        toast.show({
          placement: "top",
          id: notification._id,
          render: () => (
            <Pressable
              onPress={() => handleNotificationPopupPress(notification)}
            >
              <NotificationBubble
                title={notification.title}
                body={notification.body}
                onClose={(e) => {
                  e.stopPropagation();
                  toast.close(notification._id);
                }}
              />
            </Pressable>
          ),
        });
        dispatch(notificationSlice.actions.incrementBadgeCount(1));
        dispatch(
          notificationSlice.actions.addNotifToReceived(notification._id)
        );
      }
    });
  }, [data?.docs, notificationFeatureConfiguration]);

  return null;
};
