import {
  Box,
  Button,
  CloseIcon,
  HStack,
  IconButton,
  ScrollView,
  Text,
  useTheme,
  VStack,
} from "native-base";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ModalComponentProp } from "react-native-modalfy";
import { ModalStackParams } from "./modal-stack";
import { Dimensions, Platform } from "react-native";
import WebView from "react-native-webview";
import BouncyCheckbox from "react-native-bouncy-checkbox/build/dist/BouncyCheckbox";
import { Routes } from "../../../navigation/routes";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { AppStackParamList } from "../../../navigation/navigators/app-stack.navigator";
import { useTrackEvent } from "../hooks/use-track-event";
import { events } from "../../../constants/analytics.constants";
import { useTimer } from "react-use-precision-timer";
import { HtmlRenderer } from "../components/html-renderer.component";

type Props = ModalComponentProp<ModalStackParams, void, "InstructionsModal">;
export const InstructionsModal = (props: Props) => {
  const navigation = useNavigation<NavigationProp<AppStackParamList>>();
  const { width } = Dimensions.get("window");
  const theme = useTheme();
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const track = useTrackEvent();
  const timer = useTimer({ startImmediately: true });

  const filterContent = (content: string) => {
    let result = content;
    result = result.replace(/<p><br><\/p>/g, "");
    return result;
  };

  const defaultTrackingMeta = {
    popupId: props.modal.params?.popupId || "",
    popupTriggerType: props.modal.params?.triggerType!,
  };

  useEffect(() => {
    track(events.popupOpened, defaultTrackingMeta);
  }, []);

  const htmlContent = useMemo(() => {
    return filterContent(props.modal.params!.instructions);
  }, [props.modal.params]);

  const { continueActionType, continueActionMeta } = props.modal.params ?? {};

  const isCheckboxRequired = continueActionMeta?.isCheckboxRequired || false;

  // set the default value of the [contentType] to HTML
  const contentType = props.modal.params?.type ?? "html";

  const handleClose = () => {
    track(events.popupClosed, {
      ...defaultTrackingMeta,
      elapsedTimeOpened: timer.getElapsedStartedTime(),
    });
    props.modal.closeModal("InstructionsModal", props.modal.params?.onClose);
  };

  // close the modal automatically after the timer expires
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (continueActionType === "time") {
      const additionalSeconds = continueActionMeta?.time || 5;
      timer = setTimeout(() => {
        track(events.popupAutomaticallyClosed, defaultTrackingMeta);
        handleClose();
      }, additionalSeconds * 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [continueActionType, continueActionMeta]);

  const handleLinkPress = useCallback((url: string) => {
    navigation.navigate(Routes.APP_STACK__IN_APP_BROWSER, {
      url: url,
    });
  }, []);

  const modalWidth = useMemo(() => width * 0.8, []);

  return (
    <VStack
      variant="screen"
      borderRadius="xl"
      p={5}
      space="5"
      width={width * 0.95}
      maxWidth={Platform.OS === "web" ? "625px" : width}
    >
      {/*only display the x icon once there is no configured continue button type*/}
      {!continueActionType && (
        <Box position="absolute" top="1" right="1" zIndex={10}>
          <IconButton
            icon={<CloseIcon color="medGrey" />}
            onPress={handleClose}
          />
        </Box>
      )}

      <VStack space="1">
        {contentType === "qualtrics" && (
          <Box height="600px" width="100%">
            <WebView
              incognito={true}
              source={{
                uri: `https://duke.qualtrics.com/jfe/form/${
                  props.modal.params!.instructions
                }`,
              }}
              style={{ height: 100, width: "100%" }}
              androidLayerType="software"
            />
          </Box>
        )}

        {contentType === "html" && (
          <ScrollView
            maxHeight="600px"
            width="100%"
            showsVerticalScrollIndicator={false}
          >
            <HtmlRenderer
              htmlContent={htmlContent}
              modalWidth={modalWidth}
              handleLinkPress={handleLinkPress}
            />
          </ScrollView>
        )}
      </VStack>

      {continueActionType === "button" && (
        <VStack alignItems="flex-end">
          <Button
            variant="unstyled"
            size="sm"
            onPress={() => {
              track(events.popupConsent, defaultTrackingMeta);
              handleClose();
            }}
            backgroundColor="xLightGrey"
            borderRadius="xl"
          >
            <Text variant="button" bold>
              {continueActionMeta?.buttonLabel || "Continue"}
            </Text>
          </Button>
        </VStack>
      )}

      {continueActionType === "checkbox" && (
        <HStack
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          backgroundColor="xLightGrey"
          p={3}
          minHeight="65px"
          borderRadius="xl"
        >
          <BouncyCheckbox
            textComponent={
              <Text variant="button" ml={1} fontWeight="600" color="text-black">
                {continueActionMeta?.checkboxLabel || "I agree"}
                <Text variant="button" color="text-black">
                  {isCheckboxRequired ? "*" : ""}
                </Text>
              </Text>
            }
            fillColor={theme.colors.primary as unknown as string}
            isChecked={isConsentChecked}
            useBuiltInState={false}
            size={18}
            onPress={(isChecked) => {
              setIsConsentChecked(!isChecked);
              track(events.popupCheckboxValueChange, {
                value: !isChecked,
                ...defaultTrackingMeta,
              });
            }}
          />
          {(!isCheckboxRequired ||
            (isCheckboxRequired && isConsentChecked)) && (
            <Button
              variant="unstyled"
              size="sm"
              onPress={() => {
                track(events.popupConsent, defaultTrackingMeta);
                handleClose();
              }}
            >
              <Text variant="button" bold>
                {continueActionMeta?.buttonLabel || "Continue"}
              </Text>
            </Button>
          )}
        </HStack>
      )}
    </VStack>
  );
};
