import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const formatDateTime = (date: Date | string): string => {
  return dayjs(date).format("MMM D [at] h:mm A");
};

export const formatDateTimeRelative = (date: Date | string): string => {
  const now = dayjs();
  const targetDate = dayjs(date);

  const diffInSeconds = now.diff(targetDate, "second"); // Difference in seconds
  const isFuture = diffInSeconds < 0; // Check if the date is in the future
  const absDiffInSeconds = Math.abs(diffInSeconds); // Absolute difference

  const days = Math.floor(absDiffInSeconds / (60 * 60 * 24)); // Calculate full days
  const hours = Math.floor((absDiffInSeconds % (60 * 60 * 24)) / (60 * 60)); // Calculate full hours
  const minutes = Math.floor((absDiffInSeconds % (60 * 60)) / 60); // Calculate remaining minutes
  const seconds = absDiffInSeconds % 60; // Calculate remaining seconds

  let relativeTime = "";
  if (days > 0) relativeTime = `${days}d`; // If days > 0, show days
  else if (hours > 0) relativeTime = `${hours}h`; // If hours > 0, show hours
  else if (minutes > 0)
    relativeTime = `${minutes}m`; // If minutes > 0, show minutes
  else relativeTime = `${seconds}s`; // If nothing else, show seconds

  return relativeTime;
};
