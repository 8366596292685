import {
  Box,
  HStack,
  Pressable,
  Spinner,
  Text,
  View,
  VStack,
} from "native-base";
import React, { useCallback, useState } from "react";
import { Notification } from "../../services/api-service-sub-services/notifications-api-service";
import { NotificationItemComponent } from "./notification-item.component";
import { SwipeListView } from "react-native-swipe-list-view";
import { ListRenderItemInfo } from "react-native";
import { Feather } from "@expo/vector-icons";
import _ from "lodash";
import { EmptyTrayIcon } from "../core/icons/empty-tray-icon";

export type NotificationScreenProps = {
  notifications: Notification[];
  userId: string;
  isFetchingNotifications: boolean;
  updatedNotificationHashmap: { [key: string]: boolean };
  onBottomReached: () => Promise<void>;
  onNotificationClick: (
    notification: Notification,
    isSeen: boolean
  ) => Promise<void>;
  onNotificationSwipe: (notificationId: string) => Promise<void>;
};

export const NotificationScreen: React.FC<NotificationScreenProps> = ({
  updatedNotificationHashmap,
  onNotificationClick,
  isFetchingNotifications,
  userId,
  onBottomReached,
  ...props
}) => {
  const [swipeListWidth, setSwipeListWidth] = useState(0);

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<Notification>) => {
      const isSeen =
        item._id in updatedNotificationHashmap
          ? updatedNotificationHashmap[item._id]
          : item.seenBy.some(({ user }) => user === userId);

      return (
        <Pressable
          backgroundColor="white"
          onPress={() => {
            onNotificationClick(item, isSeen);
          }}
        >
          <NotificationItemComponent notification={item} isSeen={isSeen} />
        </Pressable>
      );
    },
    [updatedNotificationHashmap, onNotificationClick, userId]
  );

  const renderHiddenItem = useCallback(
    ({ item }: ListRenderItemInfo<Notification>) => {
      const isSeen =
        item._id in updatedNotificationHashmap
          ? updatedNotificationHashmap[item._id]
          : item.seenBy.some(({ user }) => user === userId);

      return (
        <View
          backgroundColor="primary"
          w="100%"
          h="100%"
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
        >
          <View
            pr={8}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Feather
              name={isSeen ? "eye-off" : "eye"}
              size={16}
              color="white"
            />
            <Text fontSize={14} color="white">
              Mark as {isSeen ? "unread" : "read"}
            </Text>
          </View>
        </View>
      );
    },
    [updatedNotificationHashmap, userId]
  );

  const renderFooter = useCallback(() => {
    if (!isFetchingNotifications) return null;

    return (
      <Box py={2} display="flex" justifyContent="center">
        <HStack alignItems="center" space={2}>
          <Spinner color="black" size="sm" />
          <Text>Loading...</Text>
        </HStack>
      </Box>
    );
  }, [isFetchingNotifications]);

  const debounceOnEndReached = useCallback(
    _.debounce(() => onBottomReached(), 300),
    [onBottomReached]
  );

  return (
    <VStack
      safeAreaTop
      variant="scrollable-screen"
      backgroundColor="xLightGrey"
    >
      <Box px={5} mb={3}>
        <Text variant="heading5">Notifications</Text>
      </Box>

      {props.notifications.length > 0 ? (
        <Box mx={5} borderTopRadius="3xl" overflow="hidden" flex={1}>
          <SwipeListView
            showsVerticalScrollIndicator={false}
            disableRightSwipe
            friction={14}
            keyExtractor={(item) => item._id}
            data={props.notifications}
            renderItem={renderItem}
            renderHiddenItem={renderHiddenItem}
            swipeGestureEnded={(rowKey, data) => {
              const translatedXValue = Math.abs(data.translateX);
              const hasSwipedThirdOfTheWay =
                translatedXValue >= swipeListWidth / 3;

              if (hasSwipedThirdOfTheWay) {
                props.onNotificationSwipe(rowKey);
              }
            }}
            onContentSizeChange={(w) => setSwipeListWidth(w)}
            onEndReached={debounceOnEndReached}
            ListFooterComponentStyle={{
              borderBottomEndRadius: 24,
              borderBottomStartRadius: 24,
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
            }}
            // Don't render unless there's at least 1 item
            // Otherwise it will start at the bottom
            {...(props.notifications?.length && {
              ListFooterComponent: renderFooter,
            })}
          />
        </Box>
      ) : (
        <Box alignItems="center" justifyContent="center" px={5} flex={1}>
          <Box alignItems="center" justifyContent="center" p={3}>
            <EmptyTrayIcon width={150} />
            <Text mt={2} variant="heading5" bold>
              Come back later
            </Text>
            <Text variant="subtitle1">
              Looks like there's nothing to show just yet
            </Text>
          </Box>
        </Box>
      )}
    </VStack>
  );
};
