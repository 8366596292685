import { events, Modules } from "../../../constants/analytics.constants";

export const getModuleByEvent = (event: string): string => {
  switch (event) {
    case events.userSignedUp:
    case events.userSignedIn:
    case events.userEnteredInvalidInviteCode:
      return Modules.AUTHENTICATION;

    case events.unableToUpdatesFromEAS:
    case events.userShownAnErrorMessage:
      return Modules.GENERAL;

    case events.userNavigatedExternalLink:
    case events.userChangedExternalLink:
    case events.userOpenedUrlFromApp:
    case events.userClosedInAppBrowser:
      return Modules.IN_APP_BROWSER;

    case events.userStartedSurvey:
    case events.userCompletedSurvey:
    case events.userRedirectedFromAnExistingCompletedSurvey:
      return Modules.SURVEYS;

    case events.userCreatedComment:
    case events.userOpenedCommentsThead:
    case events.userUnableToCreateComment:
    case events.userAddedInteractionToComment:
    case events.userCancelledCreatingComment:
    case events.commentEnteredViewport:
    case events.commentDwellTimeMeasured:
    case events.commentPositionMeasured:
      return Modules.COMMENTS;

    case events.userStoppedWritingPost:
    case events.userAddedInteractionToPost:
    case events.useOpenedNewPostScreenFromProfile:
    case events.userOpenedSinglePost:
    case events.userCancelledCreatingPost:
    case events.postEnteredViewport:
    case events.postExitedViewport:
    case events.postDwellTimeMeasured:
    case events.userUnableToCreatePost:
    case events.userAddedImageToPost:
    case events.userCreatedPost:
    case events.postPositionMeasured:
      return Modules.POSTS;

    case events.userViewedFeed:
    case events.userRefreshedFeed:
    case events.userReadTreatmentArmInstructions:
      return Modules.FEED;

    case events.userViewedProfile:
    case events.userViewedOwnProfile:
    case events.userFollowedProfile:
    case events.userUnfollowedProfile:
    case events.userCompletedProfileSetup:
      return Modules.PROFILE;

    case events.userViewedNotifications:
    case events.userReadNotification:
    case events.userUnreadNotification:
    case events.userOpenedNotification:
    case events.userLoadedMoreNotifications:
      return Modules.NOTIFICATIONS;

    case events.userOpenedSettingsScreen:
      return Modules.SETTINGS;

    case events.popupOpened:
    case events.popupClosed:
    case events.popupCheckboxValueChange:
    case events.popupConsent:
    case events.popupAutomaticallyClosed:
      return Modules.POPUPS;

    default:
      return "Unspecified";
  }
};
